<template>
  <b-card header-tag="header" footer-tag="footer">
    <template v-slot:header>
      <h6 class="mb-0" v-if="showName == true">Temperature control ({{device.object}})</h6>
      <h6 class="mb-0" v-else>Temperature control</h6>
    </template>
    <b-button-toolbar aria-label="Toolbar with button groups and dropdown menu">
    <b-button-group class="mx-1">
      <b-button variant="outline-info" @click="setCool()">Cool</b-button>
      <b-button @click="setOff()">Off</b-button>
      <b-button variant="outline-danger" @click="setHeat()">Heat</b-button>
    </b-button-group>
    </b-button-toolbar>
    <br>
    Press for half a second until you see a white ring to change the target temperature, and select cool or heat to send the command
    <br>
    <div id="container">
      <div id="thermostat"></div>
    </div>
  </b-card>
</template>
<style>
#thermostat {
  width: 25vmin;
  height: 25vmin;
  margin: 0 auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.dial {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.dial.away .dial__ico__leaf {
  visibility: hidden;
}
.dial.away .dial__lbl--target {
  visibility: hidden;
}
.dial.away .dial__lbl--target--half {
  visibility: hidden;
}
.dial.away .dial__lbl--away {
  opacity: 1;
}
.dial .dial__shape {
  transition: fill 0.5s;
}
.dial__ico__leaf {
  fill: #13eb13;
  opacity: 0;
  transition: opacity 0.5s;
  pointer-events: none;
}
.dial.has-leaf .dial__ico__leaf {
  display: block;
  opacity: 1;
  pointer-events: initial;
}
.dial__editableIndicator {
  fill: white;
  fill-rule: evenodd;
  opacity: 0;
  transition: opacity 0.5s;
}
.dial--edit .dial__editableIndicator {
  opacity: 1;
}
.dial--state--off .dial__shape {
  fill: #222;
}
.dial--state--heating .dial__shape {
  fill: #e36304;
}
.dial--state--cooling .dial__shape {
  fill: #007af1;
}
.dial__ticks path {
  fill: rgba(255, 255, 255, 0.3);
}
.dial__ticks path.active {
  fill: rgba(255, 255, 255, 0.8);
}
.dial text {
  fill: white;
  text-anchor: middle;
  font-family: Helvetica, sans-serif;
  alignment-baseline: central;
}
.dial__lbl--target {
  font-size: 120px;
  font-weight: bold;
}
.dial__lbl--target--half {
  font-size: 40px;
  font-weight: bold;
  opacity: 0;
  transition: opacity 0.1s;
}
.dial__lbl--target--half.shown {
  opacity: 1;
  transition: opacity 0s;
}
.dial__lbl--ambient {
  font-size: 22px;
  font-weight: bold;
}
.dial__lbl--away {
  font-size: 72px;
  font-weight: bold;
  opacity: 0;
  pointer-events: none;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v26/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsiH0B4gaVc.ttf)
    format("truetype");
}
#controls {
  font-family: Open Sans;
  background-color: rgba(255, 255, 255, 0.25);
  padding: 20px;
  border-radius: 5px;
  position: absolute;
  left: 50%;
  transform: translatex(-50%);
  margin-top: 20px;
}
#controls label {
  text-align: left;
  display: block;
}
#controls label span {
  display: inline-block;
  width: 200px;
  text-align: right;
  font-size: 0.8em;
  text-transform: uppercase;
}
#controls p {
  margin: 0;
  margin-bottom: 1em;
  padding-bottom: 1em;
  border-bottom: 2px solid #ccc;
}
</style>
<script>
import { BROADCAST_ACTION,FETCH_DEVICE_PROPS } from "@/core/services/store/devices.module";
export default {
  name: "suitch-status-widget",
  components: {},
  props: {
    device: null,
    showName: {
      type: Boolean,
      default: false
    },
  },
  beforeDestroy() {
    // Unsubscribe from the store when the component is destroyed
    if (this.unsubscribe) {
      this.unsubscribe();console.log("kill_subscribe");
    }
  },
  mounted() {
    this.downloadData();
    this.unsubscribe=this.$store.subscribe((mutation, state) => {
      if(mutation.type=="setMessage"){
        // verify if the type of message is for this widget
        if(this.device!= null 
        && state.realtime.last_message.type == "props" 
        && state.realtime.last_message.token == this.device.token){
          // call again the action to get the status
          this.downloadData();
        }
      }
    });
    this.nest = new window.thermostatDial(
      document.getElementById("thermostat"),
      {
        // onSetTargetTemperature: function(v) {
        // 	document.getElementById('input_target_temperature').value = +v;
        // }
      }
    );
    this.nest.ambient_temperature = 24;
    this.nest.hvac_state = "off";
    this.nest.target_temperature = 22;
    // document.getElementById('input_ambient_temperature').addEventListener('input',function() {
    // 	nest.ambient_temperature = this.value;
    // });
    // document.getElementById('input_state').addEventListener('change',function() {
    // 	nest.hvac_state = this.value;
    // });
    // document.getElementById('input_leaf').addEventListener('change',function() {
    // 	nest.has_leaf = this.value;
    // });
    // document.getElementById('input_away').addEventListener('change',function() {
    // 	nest.away = this.value;
    // });
  },
  data() {
    return {
      unsubscribe: null,
      nest:null
    };
  },
  methods: {
    downloadData() {
      if (this.device != null) {
        this.$store.dispatch(FETCH_DEVICE_PROPS, { device_token: this.device.token })
        // go to which page after successfully login
        .then((data) => {
          // download devices list
          if (data == undefined || data == null) {
              // when null
          } else {
              data.forEach(element => {
                switch(element.command_data){
                  case "mode":
                    if (element.value=="off") {
                      this.nest.hvac_state = element.value;
                    } else {
                      this.nest.hvac_state = element.value+"ing";
                    }
                  break;
                  case "temp":
                    this.nest.ambient_temperature = Number(element.value);
                  break;
                  case "set_temp":
                    this.nest.target_temperature = Number(element.value);
                  break;
                }
              });
          }
        });
      }
    },
    setHeat(){
      this.nest.hvac_state="heating";
      this.$store
          .dispatch(BROADCAST_ACTION, { token: this.device.token,secret:this.device.secret_token,command_data:"heat:"+ String(this.nest.target_temperature)})
          // go to which page after successfully login
          .then((data) => {
            data;
            this.$bvToast.toast("The target temperature has been propagated", {
              title: `Result`,
              variant: "success",
              solid: true,
            });
            // just reenable the button and force update again
          });
    },
    setCool(){
      this.nest.hvac_state="cooling";
      this.$store
          .dispatch(BROADCAST_ACTION, { token: this.device.token,secret:this.device.secret_token,command_data:"cool:"+ String(this.nest.target_temperature)})
          // go to which page after successfully login
          .then((data) => {
            data;
            // just reenable the button and force update again
            this.$bvToast.toast("The target temperature has been propagated", {
              title: `Result`,
              variant: "success",
              solid: true,
            });
          });
    },
    setOff(){
      this.nest.hvac_state="off";
      this.$store
          .dispatch(BROADCAST_ACTION, { token: this.device.token,secret:this.device.secret_token,command_data:"off"})
          // go to which page after successfully login
          .then((data) => {
            data;
            // just reenable the button and force update again
            this.$bvToast.toast("The device has gone off", {
              title: `Result`,
              variant: "success",
              solid: true,
            });
          });
    }
  },
  watch: {
    device: function(oldval, newval) {
      oldval,newval;
      this.downloadData();
    },
  },
};
</script>
